<template>
  <div>
    <transition name="van-slide-right">
      <div v-show="visible">
        <nav-bar/>

        <div v-for="(item1,index) in keepDeclares" :key="index" style="margin-top: 10px">
          <van-cell-group>
            <van-cell>
              <template #title>
                {{ item1.keepMTime }}
              </template>
            </van-cell>
            <van-cell v-for="(item2,index2) in item1.list" :key="index2" center>
              <template #icon>
                <van-checkbox-group v-model="keepAccountsId" >
                <van-checkbox shape="square" :name="item2.id" v-if="item2.contractId === null" disabled>
                </van-checkbox>
                <van-checkbox shape="square" :name="item2.id" v-else @click="checkboxClick">
                </van-checkbox>
                </van-checkbox-group>
              </template>
              <template #title>
                <strong style="font-size: 18px;color: #333333">
                  &nbsp;&nbsp;{{ item2.feeTypeName }}
                </strong>
              </template>
              <template #label v-if="item2.contractId === null">
                <span style="margin-left: 10px;color: #e33d33">未选择业务合同</span>
              </template>
              <template #right-icon>
                <p style="color: #333333">
                <span v-if="item2.paymentType === 0">
                &nbsp;&nbsp;+{{ item2.money }}
              </span>
                  <span v-else style="color: #e33d33">
                &nbsp;&nbsp;-{{ item2.money }}
              </span>
                </p>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
<!--        <van-tabbar active-color="#ee0a24" style="padding: 20px">-->
<!--            <span style="line-height: 44px">请勾选需要纳税的费用</span><van-button type="primary" round >提 交</van-button>-->
<!--        </van-tabbar>>-->
        <van-submit-bar  button-text="提交" style="padding-bottom: 20px" @submit="onSubmit" :loading="loading" :disabled="disabled">
          <template #default>
            <span style="margin-right: 20px;color: dimgray">请勾选需要纳税的费用</span>
          </template>
        </van-submit-bar>
      </div>
    </transition>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import {addKeepDeclare, getKeepListByStatus} from "@/api/declare/declare";
import VantMessage from "@/utils/vantMessageUtils";
export default {
  name: "declaration",
  data() {
    return {
      disabled:true,
      loading:false,
      visible:false,
      list: ['a', 'b'],
      keepDeclares: [],
      checked:false,
      keepAccountsId:[]
    };
  },
  components:{
    NavBar
  },
  methods:{
    checkboxClick() {
      if (this.keepAccountsId.length > 0) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    async onSubmit() {
      this.loading = true;
      let res = await addKeepDeclare({
        keepAccountsId:this.keepAccountsId
      });
      this.loading = false;
      if (res) {
        VantMessage.successMsg("提交成功！");
        await this.$router.replace("/taxPayment");
      }
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    async getKeepListByStatus() {
      let res = await getKeepListByStatus(null);
      if (res.length >0) {
        this.keepDeclares = res;
      }

    }
  },
  mounted() {
    this.visible = true;
    this.getKeepListByStatus();
    document.title = this.$route.meta.title
  }
}
</script>

<style scoped>
</style>
